// src/components/highyield/highyield-table.tsx

import React from 'react';
import delete_icon from '../../images/delete-bin.svg';
import ToastMessage from '../ui/toastMessage/toast-message';
import { apiCall } from '../../utils/api';

interface HighYieldTableProps {
  data: string[];
  setData: React.Dispatch<React.SetStateAction<string[]>>;
  clientName: string;
  showToast: boolean;
  setToast: React.Dispatch<React.SetStateAction<boolean>>;
  toastMsg: string;
  setToastMsg: React.Dispatch<React.SetStateAction<string>>;
  toastStatus: boolean;
  setToastStatus: React.Dispatch<React.SetStateAction<boolean>>;

  // New optional callback to handle row click for chat
  onDealClick?: (dealName: string) => void;
}

const HighYieldTable: React.FC<HighYieldTableProps> = ({
  data,
  setData,
  clientName,
  showToast,
  setToast,
  toastMsg,
  setToastMsg,
  toastStatus,
  setToastStatus,
  onDealClick,
}) => {
  /**
   * Handler to delete a deal.
   */
  const handleDeleteDeal = async (dealName: string) => {
    if (!clientName) {
      setToastMsg('Client name is not available');
      setToastStatus(false);
      setToast(true);
      return;
    }

    if (window.confirm(`Are you sure you want to delete the deal: ${dealName}?`)) {
      try {
        const response = await apiCall(`/collection/${clientName}/company/${dealName}`, {
          method: 'DELETE',
        });
        if (response.status === 200) {
          const newData = data.filter((item) => item !== dealName);
          setData(newData);
          setToastMsg('Deal deleted successfully');
          setToastStatus(true);
          setToast(true);
        }
      } catch (error) {
        console.error('Error deleting deal:', error);
        setToastMsg('Something went wrong while deleting the deal.');
        setToastStatus(false);
        setToast(true);
      }
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-200">
          <tr>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">Deal Name</th>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((deal, index) => (
            <tr
              key={index}
              className="border-b last:border-b-0 hover:bg-gray-100 cursor-pointer"
              // If onDealClick is provided, trigger it on row click
              onClick={() => onDealClick?.(deal)}
            >
              <td className="py-4 px-6 text-sm text-gray-800 text-center">{deal}</td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                <img
                  src={delete_icon}
                  alt="Delete"
                  className="cursor-pointer w-5 h-5 mx-auto"
                  onClick={(e) => {
                    e.stopPropagation(); // stop row click
                    handleDeleteDeal(deal);
                  }}
                />
              </td>
            </tr>
          ))}
          {data.length === 0 && (
            <tr>
              <td className="py-4 px-6 text-center text-gray-500" colSpan={2}>
                No deals found. Try creating one.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Toast Message */}
      {showToast && (
        <ToastMessage
          isSuccess={toastStatus}
          toastMsg={toastMsg}
          setToast={setToast}
        />
      )}
    </div>
  );
};

export default HighYieldTable;
