// src/pages/highYieldPage.tsx
import React from 'react';
import Layout from '../components/ui/layout/layout';
import HighYieldMain from '../components/highyield/highyield-main';

const HighYieldPage: React.FC = () => {
  return (
    <Layout>
      <HighYieldMain />
    </Layout>
  );
};

export default HighYieldPage;
