// src/components/highyield/analyse-highyield.tsx

import React, { useState, useEffect, useRef } from 'react';
import Input from '../ui/input/input';
import sendIcon from '../../images/icon _send_message.svg'; // Ensure consistent naming
import DarkSpinner from '../../images/DarkSpinner.gif';
import BrightSpinner from '../../images/Spinner.gif';
import ReactMarkdown from 'react-markdown';
import { apiCall } from '../../utils/api';

interface MessageProps {
  personName: 'User' | 'AI';
  message: string;
}

interface AnalyseHighYieldProps {
  companyName: string;      // The chosen High Yield deal name
  clientName: string;       // The client's “collection” name
  onCloseChat: () => void;  // A callback to return to the main table
}

const AnalyseHighYield: React.FC<AnalyseHighYieldProps> = ({
  companyName,
  clientName,
  onCloseChat,
}) => {
  const [messageList, setMessageList] = useState<MessageProps[]>([]);
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [filenames, setFilenames] = useState<string[]>([]);
  const [selectedFilenames, setSelectedFilenames] = useState<string[]>([]);
  const chatHistoryRef = useRef<HTMLDivElement>(null);

  /**
   * Fetch filenames for the selected High Yield deal.
   */
  useEffect(() => {
    if (clientName && companyName) {
      fetchFilenames(clientName, companyName);
    }
  }, [clientName, companyName]);

  const fetchFilenames = async (client: string, company: string) => {
    try {
      // Example GET: /filenames-highyield/:clientName?companyName=X
      const response = await apiCall(
        `/filenames-highyield/${encodeURIComponent(client)}`,
        {
          params: { companyName: company },
        }
      );
      setFilenames(response);
    } catch (err) {
      console.error('Failed to fetch filenames:', err);
    }
  };

  /**
   * Utility to strip out the appended "Related Documents" (if any).
   */
  const cleanMessage = (msg: string): string => {
    const relatedDocIndex = msg.indexOf('**Related Documents:**');
    if (relatedDocIndex === -1) return msg;
    return msg.substring(0, relatedDocIndex).trim();
  };

  /**
   * Send a new chat message to the High Yield endpoint.
   */
  const handleSend = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!message.trim()) {
      alert('Please enter a message before sending.');
      return;
    }
    setLoading(true);

    try {
      // Build chat history
      const chatHistory = messageList.map((chat) => ({
        role: chat.personName === 'User' ? 'user' : 'assistant',
        content: cleanMessage(chat.message),
      }));

      // POST to your new /chatHighYield route
      const response = await apiCall(`/chatHighYield`, {
        method: 'POST',
        data: {
          collectionName: clientName, // uses highyielddbName on the server
          companyName,
          message,
          chatHistory,
          filenames: selectedFilenames,
        },
      });

      const aiReply = response?.reply || 'No reply received';

      setMessageList((prev) => [
        ...prev,
        { personName: 'User', message },
        { personName: 'AI', message: aiReply },
      ]);
      setMessage('');
    } catch (err) {
      console.error('Failed to send message:', err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Toggle selection of filenames (similar to `analyse-deal.tsx`).
   */
  const handleFilenameSelection = (filename: string) => {
    if (selectedFilenames.includes(filename)) {
      setSelectedFilenames(selectedFilenames.filter((name) => name !== filename));
    } else {
      setSelectedFilenames([...selectedFilenames, filename]);
    }
  };

  /**
   * Scroll chat to the bottom on new messages.
   */
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTo({
        top: chatHistoryRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messageList]);

  return (
    <div className="flex flex-col bg-white shadow rounded-lg h-[calc(100vh_-_77px)]">
      {/* Top Bar with close button */}
      <div className="flex items-center justify-between p-4 border-b border-gray-200">
        <h2 className="font-semibold text-lg">High-Yield: {companyName}</h2>
        <button
          onClick={onCloseChat}
          className="text-sm text-gray-600 hover:text-gray-900 px-3 py-1 border border-gray-300 rounded"
        >
          Return to Table
        </button>
      </div>

      {/* Filename Selection */}
      <div className="border-b border-gray-200 p-4">
        <h4 className="font-semibold mb-2">Select Filenames:</h4>
        <div className="flex flex-wrap gap-3">
          {filenames.map((fname, index) => (
            <label key={index} className="flex items-center space-x-2 text-sm">
              <input
                type="checkbox"
                value={fname}
                checked={selectedFilenames.includes(fname)}
                onChange={() => handleFilenameSelection(fname)}
                className="form-checkbox h-4 w-4 text-indigo-600"
              />
              <span>{fname}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Chat Window */}
      <div ref={chatHistoryRef} className="flex-1 overflow-y-auto p-4 space-y-3">
        {messageList.map((item, idx) => {
          const isUser = item.personName === 'User';
          return (
            <div key={idx} className={`flex ${isUser ? 'justify-end' : 'justify-start'}`}>
              <div
                className={`rounded-lg px-4 py-2 text-sm max-w-md break-words ${
                  isUser
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-100 text-gray-900'
                }`}
              >
                <ReactMarkdown>{item.message}</ReactMarkdown>
              </div>
            </div>
          );
        })}
        {loading && (
          <div className="text-center py-4">
            <img src={DarkSpinner} alt="Loading..." className="w-12 h-12 mx-auto" />
          </div>
        )}
      </div>

      {/* Input Box */}
      <div className="border-t border-gray-200 p-3 flex items-center space-x-2">
        <Input
          className="w-full border rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-indigo-600"
          placeholder="Message about this High Yield..."
          type="text"
          disabled={loading || selectedFilenames.length === 0}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          onClick={handleSend}
          disabled={!message.trim() || loading || selectedFilenames.length === 0}
          className="disabled:opacity-50 disabled:cursor-not-allowed bg-indigo-600 hover:bg-indigo-700 text-white rounded-full p-2 transition-colors"
        >
          {loading ? (
            <img src={BrightSpinner} alt="Sending..." className="w-5 h-5" />
          ) : (
            <img src={sendIcon} alt="Send" className="w-5 h-5" />
          )}
        </button>
      </div>
    </div>
  );
};

export default AnalyseHighYield;
