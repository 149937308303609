// src/components/legal/legal-main.tsx
import React, { useEffect, useState } from 'react';
import PageHeader from '../ui/pageHeader/pageHeader';
import { useQuery } from '@tanstack/react-query';
import LegalTable from './legal-table';
import { getClientName } from '../../utils/authUtils';
import { apiCall } from '../../utils/api';
import ToastMessage from '../ui/toastMessage/toast-message';
import LegalAnalysis from './legal-analysis';
import CompareSFAs from './CompareSFAs';

const LegalMain = () => {
  const [tableData, setTableData] = useState<string[]>([]);
  const [clientName, setClientName] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  const [header, setHeader] = useState<string>('Legal Documents'); // Manage header state
  const [dealName, setDealName] = useState<string>(''); // Selected deal name
  const [showCompareSFAs, setShowCompareSFAs] = useState<boolean>(false); // State for CompareSFAs view
  const [showChat, setShowChat] = useState<boolean>(false); // Manage chat view

  // Fetch client name on component mount
  useEffect(() => {
    const fetchClientName = async () => {
      try {
        const name = await getClientName();
        if (name) {
          setClientName(name);
        } else {
          console.warn('No client name returned');
        }
      } catch (error) {
        console.error('Failed to fetch client name:', error);
      }
    };

    fetchClientName();
  }, []);

  // Fetch the legal deals
  const { data: legalDeals, error, isLoading } = useQuery({
    queryKey: ['portfolio-data', clientName],
    queryFn: async () => {
      if (!clientName) return [];
      const response = await apiCall(`/portfolio?collectionName=${encodeURIComponent(clientName)}`);
      return response;
    },
    enabled: !!clientName,
  });

  useEffect(() => {
    if (legalDeals) {
      setTableData(legalDeals);
    }
  }, [legalDeals]);

  // Compare SFAs
  const openCompareSFAs = () => {
    setShowCompareSFAs(true);
    setShowChat(false);
    setDealName('');
  };
  const closeCompareSFAs = () => {
    setShowCompareSFAs(false);
  };

  // Chat
  const openChat = (dealName: string) => {
    setDealName(dealName);
    setShowChat(true);
    setShowCompareSFAs(false);
  };
  const closeChat = () => {
    setDealName('');
    setShowChat(false);
  };

  if (isLoading) {
    return (
      <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
        <PageHeader title="Legal Documents" />
        <p>Loading data...</p>
      </div>
    );
  }

  // If showing CompareSFAs
  if (showCompareSFAs) {
    return (
      <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
        <div className="flex items-center justify-between mb-6">
          <PageHeader title="Compare SFAs" />
          <button
            onClick={closeCompareSFAs}
            className="text-sm text-gray-600 hover:text-gray-900 px-3 py-1 border border-gray-300 rounded"
          >
            Return to Table
          </button>
        </div>

        <CompareSFAs
          clientName={clientName}
          onClose={closeCompareSFAs}
          setToast={setShowToast}
          setToastMsg={setToastMsg}
          setToastStatus={setToastStatus}
        />
        
        {showToast && (
          <ToastMessage isSuccess={toastStatus} toastMsg={toastMsg} setToast={setShowToast} />
        )}
      </div>
    );
  }

  // If showing chat
  if (showChat && dealName) {
    return (
      <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
        <LegalAnalysis
          dealName={dealName}
          clientName={clientName}
          onCloseChat={closeChat}
        />
        {showToast && (
          <ToastMessage isSuccess={toastStatus} toastMsg={toastMsg} setToast={setShowToast} />
        )}
      </div>
    );
  }

  // Otherwise, show the table
  return (
    <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
      {/* Top Bar */}
      <div className="flex items-center justify-between mb-6">
        <PageHeader title="Legal Documents" />
        <button
          onClick={openCompareSFAs}
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition-all duration-200"
        >
          Compare SFAs
        </button>
      </div>

      {/* Legal Table */}
      <LegalTable
        data={tableData}
        setData={setTableData}
        clientName={clientName}
        showToast={showToast}
        setToast={setShowToast}
        toastMsg={toastMsg}
        setToastMsg={setToastMsg}
        toastStatus={toastStatus}
        setToastStatus={setToastStatus}
        setHeader={setHeader}
        setDealName={setDealName}
        onDealClick={openChat} // ✅ Pass the handler here
      />

      {/* Toast */}
      {showToast && (
        <ToastMessage isSuccess={toastStatus} toastMsg={toastMsg} setToast={setShowToast} />
      )}
    </div>
  );
};

export default LegalMain;
