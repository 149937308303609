// src\components\monitoring\monitoring-table.tsx
import React, { useState, useEffect } from 'react';
import Button from '../ui/button/button';
import ToastMessage from '../ui/toastMessage/toast-message';
import Spinner from '../../images/DarkSpinner.gif';
import cancel_icon from '../../images/icon _cancel.svg';
import InitializeMonitoringModal from './initialize-monitoring-modal';
import ReportIcon from '../../images/report.svg';
import delete_icon from '../../images/delete-bin.svg';
import ReportModal from './report-modal';
import UpdateMonitoringModal from './update-monitoring-modal';
import file_upload from '../../images/icon_uploadmonitoring.svg';
import { apiCall } from '../../utils/api.ts';
import axios from 'axios';

interface MonitoringTableProps {
  data: string[];
  setData: React.Dispatch<React.SetStateAction<string[]>>;
  setHeader: React.Dispatch<React.SetStateAction<string>>;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  companyName: string;
  clientName: string;
  setReportDate: (date: string) => void;
}

const MonitoringTable: React.FC<MonitoringTableProps> = ({
  data,
  setData,
  setHeader,
  companyName,
  setCompanyName,
  clientName,
  setReportDate,
}) => {
  const [file, setFile] = useState<{ [key: string]: File | null }>({});
  const [uploading, setUploading] = useState<{ [key: string]: boolean }>({});
  const [initialized, setInitialized] = useState<{ [key: string]: boolean }>({});
  const [selectedDate, setSelectedDate] = useState<{ [key: string]: string }>({});
  const [selectedFrequency, setSelectedFrequency] = useState<{ [key: string]: string }>({});
  const [selectedNextReportDate, setSelectedNextReportDate] = useState<{ [key: string]: string }>({});
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reportGenerating, setReportGenerating] = useState<{ [key: string]: boolean }>({});
  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [selectedReportDate, setSelectedReportDate] = useState<string>('');
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  useEffect(() => {
    if (!clientName || !data || data.length === 0) {
      return;
    }

    const fetchMostRecentDocument = async (item: string) => {
      try {
        const mostRecentDocument  = await apiCall(
          `/most-recent-document?collectionName=${clientName}&companyName=${item}`
        );
        if (mostRecentDocument) {
          setSelectedDate((prev) => ({ ...prev, [item]: mostRecentDocument.report_date }));
          setSelectedFrequency((prev) => ({ ...prev, [item]: mostRecentDocument.report_frequency }));
          setSelectedNextReportDate((prev) => ({ ...prev, [item]: mostRecentDocument.nextreport_date }));
        }
      } catch (error) {
        console.error('Failed to fetch the most recent document:', error);
      }
    };

    data.forEach((item) => {
      fetchMostRecentDocument(item);
    });
  }, [clientName, data]);

  const handleSelectReportDate = async (reportDate: string, format: string) => {
    setSelectedReportDate(reportDate);

    try {
      setReportGenerating((prev) => ({ ...prev, [selectedCompany]: true }));

      const response = await apiCall(`/generate-monitoring-report`, {
        method: 'POST',
        data: {
          collectionName: clientName,
          companyName: selectedCompany,
          reportDate: reportDate,
          format: format,
        },
        responseType: 'blob',
        transformResponse: (res: any) => res,
      });

      const fileExtension = format === 'word' ? 'docx' : 'pdf';
      const mimeType =
        format === 'word'
          ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          : 'application/pdf';
      const filename = `${selectedCompany}-${reportDate}-Monitoring-Report.${fileExtension}`;

      const blob = new Blob([response.data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);

      setShowToast(true);
      setToastMsg(`Report generated successfully as ${fileExtension.toUpperCase()}`);
      setToastStatus(true);

    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        console.error('Failed to generate report:', err);
      } else {
        console.error('An unknown error occurred:', err);
      }
      setToastMsg('Failed to generate report');
      setToastStatus(false);
      setShowToast(true);
    } finally {
      setReportGenerating((prev) => ({ ...prev, [selectedCompany]: false }));
    }
  };

  const openModal = (companyName: string) => {
    setSelectedCompany(companyName);
    setShowUpdateModal(true);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFile((prev) => ({ ...prev, [name]: files[0] }));
    }
  };

  const openGenerateReportModal = (companyName: string) => {
    setSelectedCompany(companyName);
    setShowReportModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setReportGenerating((prev) => ({ ...prev, [selectedCompany]: false }));
  };

  const handleRowDoubleClick = (name: string, reportDate: string) => {
    setCompanyName(name);
    setHeader(name);
    setReportDate(reportDate);
  };

  const handleDeleteFile = (name: string) => {
    setFile((prev) => ({ ...prev, [name]: null }));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setSelectedDate((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>, name: string) => {
    setSelectedFrequency((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const initializeMonitoring = async (name: string) => {
    if (!file[name]) {
      alert('Please select a file to initialize');
      return;
    }

    setUploading((prev) => ({ ...prev, [name]: true }));

    try {
      const formData = new FormData();
      formData.append('clientName', clientName);
      formData.append('companyName', name);
      formData.append('pdfFile', file[name]!);
      formData.append('pdfName', file[name]!.name);
      formData.append('reportDate', selectedDate[name] || '');
      formData.append('reportFrequency', selectedFrequency[name] || 'Quarterly');

      const response = await apiCall('/initialize-monitoring', {
        method: 'POST',
        data: formData,
      });

      const result = response;

      if (
        response.status === 200 &&
        result.message === 'Monitoring initialized and skeleton created successfully'
      ) {
        setInitialized((prev) => ({ ...prev, [name]: true }));
        setShowToast(true);
        setToastMsg('Deal initialized successfully');
        setToastStatus(true);
      } else {
        throw new Error('Initialization failed');
      }
    } catch (error) {
      setShowToast(true);
      setToastMsg('Failed to initialize monitoring');
      setToastStatus(false);
      console.error('Error initializing monitoring:', error);
    } finally {
      setUploading((prev) => ({ ...prev, [name]: false }));
    }
  };

  return (
    <div className="overflow-x-auto">
      {/* Replicated styling from HighYield */}
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-200">
          <tr>
            <th className="py-3 px-6 text-center  text-sm font-medium text-gray-700">Company Name</th>
            <th className="py-3 px-6 text-center  text-sm font-medium text-gray-700">Latest Report</th>
            <th className="py-3 px-6 text-center  text-sm font-medium text-gray-700">Next Report Due</th>
            <th className="py-3 px-6 text-center  text-sm font-medium text-gray-700">Frequency</th>
            <th className="py-3 px-6 text-center  text-sm font-medium text-gray-700">Delete</th>
            <th className="py-3 px-6 text-center  text-sm font-medium text-gray-700">Upload</th>
            <th className="py-3 px-6 text-center  text-sm font-medium text-gray-700">Report</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              className="border-b last:border-b-0 hover:bg-gray-100 cursor-pointer"
              onDoubleClick={() => handleRowDoubleClick(item, selectedDate[item] || '')}
            >
              <td className="py-4 px-6 text-sm text-gray-800">{item}</td>
              <td className="py-4 px-6 text-sm text-gray-800">
                {selectedDate[item] || 'No reports yet'}
              </td>
              <td className="py-4 px-6 text-sm text-gray-800">
                {selectedNextReportDate[item] || 'N/A'}
              </td>
              <td className="py-4 px-6 text-sm text-gray-800">
                {selectedFrequency[item] || 'N/A'}
              </td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                <img
                  src={delete_icon}
                  alt="Delete Monitoring"
                  className="cursor-pointer mx-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteFile(item);
                  }}
                />
              </td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                <img
                  src={file_upload}
                  alt="Upload Monitoring"
                  className="cursor-pointer mx-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    openModal(item);
                  }}
                />
              </td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                {reportGenerating[item] ? (
                  <img src={Spinner} alt="Loading..." className="w-8 h-8 mx-auto" />
                ) : (
                  <img
                    src={ReportIcon}
                    alt="Generate Report"
                    className="cursor-pointer mx-auto"
                    onClick={(e) => {
                      e.stopPropagation();
                      openGenerateReportModal(item);
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showReportModal && (
        <ReportModal
          setShowModal={setShowReportModal}
          clientName={clientName}
          companyName={selectedCompany}
          onSelectReportDate={handleSelectReportDate}
        />
      )}

      {showUpdateModal && (
        <UpdateMonitoringModal
          setShowModal={setShowUpdateModal}
          clientName={clientName}
          companyName={selectedCompany}
          nextReportDate={selectedDate[selectedCompany] || ''}
        />
      )}

      {showToast && (
        <ToastMessage
          isSuccess={toastStatus}
          toastMsg={toastMsg}
          setToast={setShowToast}
        />
      )}
    </div>
  );
};

export default MonitoringTable;
