// src/App.tsx
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import LoginPage from './pages/loginPage';
import NewProjectPage from './pages/newProject';
import DealAnalysisPage from './pages/dealAnalysisPage';
import LegalPage from './pages/legalPage';
import MonitoringPage from '../src/pages/monitoringPage'
import Setting from './pages/setting';
import Help from './pages/help';
import PrivateRoute from './utils/PrivateRoute';
import DataExtractionPage from './pages/dataExtractionPage';
import HighYieldPage from './pages/highYieldPage'; // or direct from HighYieldMain

function App() {
  const router = createBrowserRouter([
    {
      path: '/login',  // Login route does not need to be protected
      element: <LoginPage />,
    },
    {
      path: '/',  // Redirects to the NewProjectPage for authenticated users
      element: <PrivateRoute component={<NewProjectPage />} />,
    },
    {
      path: '/newproject',  // This can be redundant, but kept for clarity
      element: <PrivateRoute component={<NewProjectPage />} />,
    },
    {
      path: '/analysis',
      element: <PrivateRoute component={<DealAnalysisPage />} />,
    },
    {
      path: '/legal',
      element: <PrivateRoute component={<LegalPage />} />, // Add this route
    },
    {
      path: '/monitoring',
      element: <PrivateRoute component={<MonitoringPage />} />,  // Your Monitoring Page component
    },
    {
      path: '/data-extraction',
      element: <PrivateRoute component={<DataExtractionPage />} />,
    },
    {
      path: '/highyield',
      element: <PrivateRoute component={<HighYieldPage />} />, 
    },
    {
      path: '/settings',
      element: <PrivateRoute component={<Setting />} />,
    },
    {
      path: '/help',
      element: <PrivateRoute component={<Help />} />,
    },
  ]);

  return (
    <Authenticator.Provider>
      <RouterProvider router={router} />
    </Authenticator.Provider>
  );
}

export default App;
