// src\components\deal analysis\analyse-deal.tsx
import React, { useState, useEffect, useRef } from 'react';
import Input from '../ui/input/input';
import sendIcon from '../../images/icon _send_message.svg';
import DarkSpinner from '../../images/DarkSpinner.gif';
import BrightSpinner from '../../images/Spinner.gif';
import ReactMarkdown from 'react-markdown';
import ImageModal from '../ui/image-modal';
import { apiCall } from '../../utils/api';

interface MessageProps {
  personName: 'User' | 'AI';
  message: string;
}

interface AnalyseDealProps {
  companyName: string;
  clientName: string;
  onCloseChat: () => void;
}

const AnalyseDeal: React.FC<AnalyseDealProps> = ({
  companyName,
  clientName,
  onCloseChat
}) => {
  const [messageList, setMessageList] = useState<MessageProps[]>([]);
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [filenames, setFilenames] = useState<string[]>([]);
  const [selectedFilenames, setSelectedFilenames] = useState<string[]>([]);
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (clientName && companyName) {
      fetchFilenames(clientName, companyName);
    }
  }, [clientName, companyName]);

  const fetchFilenames = async (client: string, company: string) => {
    try {
      const response = await apiCall(`/filenames/${encodeURIComponent(client)}`, {
        params: { companyName: company },
      });
      setFilenames(response);
    } catch (err) {
      console.error('Failed to fetch filenames:', err);
    }
  };

  const cleanMessage = (msg: string): string => {
    const relatedDocIndex = msg.indexOf('**Related Documents:**');
    if (relatedDocIndex === -1) return msg;
    return msg.substring(0, relatedDocIndex).trim();
  };

  const handleSend = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!message.trim()) {
      alert('Please enter a message before sending.');
      return;
    }
    setLoading(true);

    try {
      const chatHistory = messageList.map((chat) => ({
        role: chat.personName === 'User' ? 'user' : 'assistant',
        content: cleanMessage(chat.message),
      }));

      const response = await apiCall(`/chat`, {
        method: 'POST',
        data: {
          collectionName: clientName,
          companyName,
          message,
          chatHistory,
          filenames: selectedFilenames,
        },
      });

      const linkedResponse = response?.reply
        ? linkifyFilenames(response.reply)
        : 'No reply received';

      setMessageList((prev) => [
        ...prev,
        { personName: 'User', message },
        { personName: 'AI', message: linkedResponse },
      ]);
      setMessage('');
    } catch (err) {
      console.error('Failed to send message:', err);
    } finally {
      setLoading(false);
    }
  };

  const linkifyFilenames = (msg: string) => {
    const relatedDocIndex = msg.indexOf('**Related Documents:**');
    if (relatedDocIndex === -1) return msg;
    const beforeDocs = msg.substring(0, relatedDocIndex);
    const docsAndAfter = msg.substring(relatedDocIndex);
    const files = docsAndAfter
      .replace('**Related Documents:** ', '')
      .split(',')
      .map((f) => f.trim());
    const linkedFiles = files
      .map((filename: string) => `[${filename}](#${filename})`)
      .join(', ');
    return `${beforeDocs}**Related Documents:** ${linkedFiles}`;
  };

  const handleLinkClick = async (filename: string) => {
    try {
      const response = await apiCall(`/slidevisual`, {
        params: {
          clientName,
          companyName,
          filename,
        },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setImageUrl(url);
    } catch (error) {
      console.error('Failed to fetch file:', error);
      alert('Failed to retrieve the file. Please try again later.');
    }
  };

  const handleFilenameSelection = (filename: string) => {
    if (selectedFilenames.includes(filename)) {
      setSelectedFilenames(selectedFilenames.filter((name) => name !== filename));
    } else {
      setSelectedFilenames([...selectedFilenames, filename]);
    }
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTo({
        top: chatHistoryRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messageList]);

  return (
    <div className="flex flex-col bg-white shadow rounded-lg h-[calc(100vh_-_77px)]">
      {/* Top Bar with close button */}
      <div className="flex items-center justify-between p-4 border-b border-gray-200">
        <h2 className="font-semibold text-lg">Deal Analysis: {companyName}</h2>
        <button
          onClick={onCloseChat}
          className="text-sm text-gray-600 hover:text-gray-900 px-3 py-1 border border-gray-300 rounded"
        >
          Return to Table
        </button>
      </div>

      {/* Filename Selection */}
      <div className="border-b border-gray-200 p-4">
        <h4 className="font-semibold mb-2">Select Filenames:</h4>
        <div className="flex flex-wrap gap-3">
          {filenames.map((fname, index) => (
            <label key={index} className="flex items-center space-x-2 text-sm">
              <input
                type="checkbox"
                value={fname}
                checked={selectedFilenames.includes(fname)}
                onChange={() => handleFilenameSelection(fname)}
                className="form-checkbox h-4 w-4 text-indigo-600"
              />
              <span>{fname}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Chat History */}
      <div ref={chatHistoryRef} className="flex-1 overflow-y-auto p-4 space-y-3">
        {messageList.map((item, index) => {
          const isUser = item.personName === 'User';
          return (
            <div key={index} className={`flex ${isUser ? 'justify-end' : 'justify-start'}`}>
              <div
                className={`rounded-lg px-4 py-2 text-sm max-w-md break-words ${
                  isUser ? 'bg-indigo-600 text-white' : 'bg-gray-100 text-gray-900'
                }`}
              >
                <ReactMarkdown
                  components={{
                    a: ({ node, ...props }) => (
                      <a
                        {...props}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!node?.children?.[0]) return;
                          const fname = (node.children[0] as any).value;
                          handleLinkClick(fname);
                        }}
                        className="underline hover:text-blue-600"
                      >
                        {props.children}
                      </a>
                    ),
                    ol: ({ node, ...props }) => <ol className="list-decimal pl-5" {...props} />,
                    ul: ({ node, ...props }) => <ul className="list-disc pl-5" {...props} />,
                    li: ({ node, ...props }) => <li className="mb-2" {...props} />,
                  }}
                >
                  {item.message}
                </ReactMarkdown>
              </div>
            </div>
          );
        })}
        {loading && (
          <div className="text-center py-4">
            <img src={DarkSpinner} alt="Loading..." className="w-12 h-12 mx-auto" />
          </div>
        )}
      </div>

      {/* Image Modal */}
      {imageUrl && (
        <ImageModal
          imageUrl={imageUrl}
          onClose={() => setImageUrl(null)}
        />
      )}

      {/* Message Input */}
      <div className="border-t border-gray-200 p-3 flex items-center space-x-2">
        <Input
          className="w-full border rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-indigo-600"
          placeholder="Message Credilyst"
          type="text"
          disabled={loading || selectedFilenames.length === 0}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          onClick={handleSend}
          disabled={!message.trim() || loading || selectedFilenames.length === 0}
          className="disabled:opacity-50 disabled:cursor-not-allowed bg-indigo-600 hover:bg-indigo-700 text-white rounded-full p-2 transition-colors"
        >
          {loading ? (
            <img src={BrightSpinner} alt="Sending..." className="w-5 h-5" />
          ) : (
            <img src={sendIcon} alt="Send" className="w-5 h-5" />
          )}
        </button>
      </div>
    </div>
  );
};

export default AnalyseDeal;
