// src/components/highyield/initialize-highyield-modal.tsx

import React, { useEffect, useState } from 'react';
import { apiCall } from '../../utils/api';
import Modal from 'react-modal';

interface InitializeHighYieldModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  clientName: string;
  setToast: React.Dispatch<React.SetStateAction<boolean>>;
  setToastMsg: React.Dispatch<React.SetStateAction<string>>;
  setToastStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Paragraph {
  id: string;
  text: string;
  recommended?: boolean; // If LLM marked recommended
  selected: boolean;     // Whether the user toggled it or not
}

// Utility: capitalize first letter, lowercase the rest
const capitalizeFirstLetter = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const InitializeHighYieldModal: React.FC<InitializeHighYieldModalProps> = ({
  setShowModal,
  clientName,
  setToast,
  setToastMsg,
  setToastStatus,
}) => {
  // Deals from the server
  const [existingDeals, setExistingDeals] = useState<string[]>([]);

  // Deal mode: new vs. existing
  const [isNewDeal, setIsNewDeal] = useState(false);

  // If using existing deal
  const [selectedDeal, setSelectedDeal] = useState('');

  // If creating new
  const [newDealName, setNewDealName] = useState('');

  // PDF file
  const [file, setFile] = useState<File | null>(null);

  // Company name
  const [companyName, setCompanyName] = useState('');

  // Paragraphs from the scanning endpoint
  const [paragraphs, setParagraphs] = useState<Paragraph[]>([]);

  // Loading states
  const [isLoadingScan, setIsLoadingScan] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch existing deals once
  useEffect(() => {
    const fetchDeals = async () => {
      if (!clientName) return;
      try {
        const response = await apiCall(
          `/highyieldportfolio?collectionName=${encodeURIComponent(clientName)}`,
          { method: 'GET' }
        );
        if (Array.isArray(response)) {
          setExistingDeals(response);
        } else {
          console.warn('Expected an array of deals, got:', response);
          setToastMsg('Failed to fetch existing deals.');
          setToastStatus(false);
          setToast(true);
        }
      } catch (err) {
        console.error('Error fetching existing deals:', err);
        setToastMsg('Error fetching existing deals.');
        setToastStatus(false);
        setToast(true);
      }
    };
    fetchDeals();
  }, [clientName, setToast, setToastMsg, setToastStatus]);

  // PDF selection -> scanning
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (!selectedFile) return;

    setFile(selectedFile);
    setIsLoadingScan(true);

    try {
      const formData = new FormData();
      formData.append('pdfFile', selectedFile);

      const response = await apiCall('/scan-prospectus-paragraphs', {
        method: 'POST',
        data: formData,
      });

      if (response.success && Array.isArray(response.paragraphs)) {
        // Build local paragraphs array
        const newParagraphs = response.paragraphs.map((p: any, idx: number) => ({
          id: p.id || `para-${idx}`,
          text: capitalizeFirstLetter(p.text || ''),
          recommended: !!p.useful,
          selected: !!p.useful, // LLM recommended => pre-selected
        })) as Paragraph[];
        setParagraphs(newParagraphs);
      } else {
        console.warn('Invalid paragraphs response:', response);
        setToastMsg('Failed to extract paragraphs. Please try again.');
        setToastStatus(false);
        setToast(true);
      }
    } catch (error) {
      console.error('Error scanning prospectus PDF:', error);
      setToastMsg('Error scanning the file. Please try again.');
      setToastStatus(false);
      setToast(true);
    } finally {
      setIsLoadingScan(false);
    }
  };

  // Switch deal mode
  const handleDealModeToggle = (mode: 'existing' | 'new') => {
    if (mode === 'new') {
      setIsNewDeal(true);
      setSelectedDeal('');
    } else {
      setIsNewDeal(false);
      setNewDealName('');
    }
  };

  // Toggle paragraph checkbox
  const handleParagraphToggle = (id: string) => {
    setParagraphs(prev =>
      prev.map(p => (p.id === id ? { ...p, selected: !p.selected } : p))
    );
  };

  // Final upload
  const handleUpload = async () => {
    if (!file) {
      setToastMsg('Please select a PDF before uploading.');
      setToastStatus(false);
      setToast(true);
      return;
    }
    if (!companyName.trim()) {
      setToastMsg('Please enter a Company Name.');
      setToastStatus(false);
      setToast(true);
      return;
    }

    // Figure out which deal name
    let dealName = selectedDeal;
    if (isNewDeal) {
      if (!newDealName.trim()) {
        setToastMsg('Please enter a new deal name.');
        setToastStatus(false);
        setToast(true);
        return;
      }
      dealName = newDealName;
    }
    if (!dealName.trim()) {
      setToastMsg('Please select or create a deal name.');
      setToastStatus(false);
      setToast(true);
      return;
    }

    // In this version, we send ALL paragraphs (both selected & unselected)
    // so the backend can handle them. The user 'selected' status is included in each object.
    // The server can filter on p.selected if needed.
    const allParagraphs = paragraphs;

    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('pdfFile', file);
      formData.append('clientName', clientName);
      formData.append('companyName', companyName);
      formData.append('dealName', dealName); // Ensure dealName is included
      // Send all paragraphs
      formData.append('paragraphs', JSON.stringify(allParagraphs));

      const response = await apiCall('/upload-prospectus', {
        method: 'POST',
        data: formData,
      });

      if (response.success) {
        setToastMsg('Prospectus uploaded and processed successfully!');
        setToastStatus(true);
        setToast(true);
        setShowModal(false);
      } else {
        setToastMsg(`Upload failed: ${response.message || 'Unknown error'}`);
        setToastStatus(false);
        setToast(true);
      }
    } catch (err) {
      console.error('Error uploading prospectus:', err);
      setToastMsg('An error occurred during upload. Please try again.');
      setToastStatus(false);
      setToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-lg w-11/12 max-w-2xl max-h-screen overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Initialize New High Yield</h2>

        {/* Deal Mode Toggle */}
        <div className="mb-4">
          <p className="font-medium mb-2">Select Deal Mode</p>
          <div className="flex space-x-2">
            <button
              onClick={() => handleDealModeToggle('existing')}
              className={`px-3 py-1 rounded ${
                !isNewDeal ? 'bg-blue-600 text-white' : 'bg-gray-200'
              }`}
            >
              Existing Deal
            </button>
            <button
              onClick={() => handleDealModeToggle('new')}
              className={`px-3 py-1 rounded ${
                isNewDeal ? 'bg-blue-600 text-white' : 'bg-gray-200'
              }`}
            >
              Create New Deal
            </button>
          </div>
        </div>

        {/* Existing Deal */}
        {!isNewDeal && (
          <div className="mb-4">
            <label htmlFor="existingDeal" className="block mb-1 font-medium">
              Choose an Existing Deal
            </label>
            <select
              id="existingDeal"
              value={selectedDeal}
              onChange={e => setSelectedDeal(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded"
            >
              <option value="">-- Select a Deal --</option>
              {existingDeals.map(deal => (
                <option key={deal} value={deal}>
                  {deal}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* New Deal */}
        {isNewDeal && (
          <div className="mb-4">
            <label htmlFor="newDealName" className="block mb-1 font-medium">
              New Deal Name
            </label>
            <input
              id="newDealName"
              type="text"
              value={newDealName}
              onChange={e => setNewDealName(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="Enter new deal name"
            />
          </div>
        )}

        {/* Company Name */}
        <div className="mb-4">
          <label htmlFor="companyName" className="block mb-1 font-medium">
            Company Name
          </label>
          <input
            id="companyName"
            type="text"
            value={companyName}
            onChange={e => setCompanyName(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded"
            placeholder="Enter Company Name"
          />
        </div>

        {/* PDF Upload */}
        <div className="mb-4">
          <label htmlFor="pdfFile" className="block mb-1 font-medium">
            Upload Prospectus (PDF)
          </label>
          <input
            id="pdfFile"
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            disabled={isLoadingScan || isSubmitting}
            className="block w-full p-2 border border-gray-300 rounded"
          />
        </div>

        {/* Scanning Spinner */}
        {isLoadingScan && (
          <div className="mb-4 text-blue-600 font-medium flex items-center">
            <p>Analyzing the prospectus...</p>
            <svg
              className="animate-spin h-5 w-5 text-blue-600 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              />
            </svg>
          </div>
        )}

        {/* Paragraph List */}
        {!isLoadingScan && paragraphs.length > 0 && (
          <div className="mb-4">
            <p className="font-medium mb-2">Suggested Paragraphs</p>
            <div className="max-h-60 overflow-y-auto border border-gray-200 p-2 rounded">
              {paragraphs.map(para => (
                <div key={para.id} className="flex items-start mb-2">
                  <input
                    type="checkbox"
                    checked={para.selected}
                    onChange={() => handleParagraphToggle(para.id)}
                    className="mt-1 mr-2"
                  />
                  <div>
                    <p className="text-sm">{para.text}</p>
                    {para.recommended && (
                      <span className="text-xs text-green-600 ml-1">
                        (Recommended)
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Footer Buttons */}
        <div className="flex justify-end mt-4 space-x-2">
          <button
            onClick={() => setShowModal(false)}
            disabled={isSubmitting}
            className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Close
          </button>
          <button
            onClick={handleUpload}
            disabled={
              isLoadingScan ||
              isSubmitting ||
              !file ||
              !companyName.trim() ||
              (!isNewDeal && !selectedDeal) ||
              (isNewDeal && !newDealName.trim())
            }
            className={`px-4 py-2 rounded transition-colors ${
              isLoadingScan ||
              isSubmitting ||
              !file ||
              !companyName.trim() ||
              (!isNewDeal && !selectedDeal) ||
              (isNewDeal && !newDealName.trim())
                ? 'bg-blue-600 opacity-50 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 text-white'
            }`}
          >
            {isSubmitting ? 'Please Wait...' : 'Upload'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default InitializeHighYieldModal;
