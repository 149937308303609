// src\components\deal analysis\deal-analysis-main.tsx
import React, { useEffect, useState } from 'react';
import PageHeader from '../ui/pageHeader/pageHeader';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import DealTable from './deal-table';
import AnalysesDeal from '../deal analysis/analyse-deal';
import { getClientName } from '../../utils/authUtils';
import { apiCall } from '../../utils/api';

const MonitorDealing = () => {
  const [tableData, setTableData] = useState<string[]>([]);
  const [clientName, setClientName] = useState<string>('');
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [showChat, setShowChat] = useState<boolean>(false);

  // Fetch client name on component mount
  useEffect(() => {
    (async () => {
      const name = await getClientName();
      if (name) setClientName(name);
    })();
  }, []);

  // Fetch company names based on the client name
  const { data, error, isLoading } = useQuery({
    queryKey: ['portfolio-data', clientName],
    queryFn: async () => {
      if (!clientName) return [];
      const response = await apiCall(`/portfolio?collectionName=${encodeURIComponent(clientName)}`);
      return response;
    },
    enabled: !!clientName,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  /**
   * Click handler to open the chat for a specific deal.
   */
  const handleDealClick = (dealName: string) => {
    setSelectedProject(dealName);
    setShowChat(true);
  };

  /**
   * Close the chat, return to the deals table.
   */
  const closeChat = () => {
    setSelectedProject('');
    setShowChat(false);
  };

  // While data is loading
  if (isLoading) {
    return (
      <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
        <PageHeader title="Deal Analysis" />
        <p>Loading portfolio data...</p>
      </div>
    );
  }

  // If chat is open
  if (showChat && selectedProject) {
    return (
      <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
        <AnalysesDeal 
          companyName={selectedProject}
          clientName={clientName}
          onCloseChat={closeChat}
        />
      </div>
    );
  }

  // Otherwise, show the table
  return (
    <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
      {/* Top Bar */}
      <div className="flex items-center justify-between mb-6">
        <PageHeader title="Deal Analysis" />
      </div>

      {/* Deal Table */}
      <DealTable
        data={tableData}
        setData={setTableData}
        clientName={clientName}
        // these are not strictly needed if your table doesn't rely on them
        setHeader={() => {}}
        companyName={selectedProject}
        setCompanyName={setSelectedProject}
        // Pass an on-click handler for each row
        onDealClick={handleDealClick}
      />
    </div>
  );
};

export default MonitorDealing;
