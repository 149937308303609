import React, { useState } from 'react';
import { apiCall } from '../../utils/api.ts';
import Spinner from '../../images/DarkSpinner.gif';
import ReportIcon from '../../images/report.svg';
import delete_icon from '../../images/delete-bin.svg';
import file_upload from '../../images/icon_uploadmonitoring.svg';
import initialize_icon from '../../images/icon_initialize.svg';
import LegalUploadModal from './legal-upload-modal';
import LegalReportModal from './legal-report-modal';
import InitializeSFAModal from './initialize-SFA.tsx';
import ToastMessage from '../ui/toastMessage/toast-message';

interface LegalTableProps {
  data: string[];
  setData: React.Dispatch<React.SetStateAction<string[]>>;
  clientName: string;
  showToast: boolean;
  setToast: React.Dispatch<React.SetStateAction<boolean>>;
  toastMsg: string;
  setToastMsg: React.Dispatch<React.SetStateAction<string>>;
  toastStatus: boolean;
  setToastStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setHeader: React.Dispatch<React.SetStateAction<string>>;
  setDealName: React.Dispatch<React.SetStateAction<string>>;
  onDealClick: (dealName: string) => void; // ✅ Added this line
}

const LegalTable: React.FC<LegalTableProps> = ({
  data,
  setData,
  clientName,
  showToast,
  setToast,
  toastMsg,
  setToastMsg,
  toastStatus,
  setToastStatus,
  setHeader,
  setDealName,
  onDealClick, // ✅ Destructure the new prop
}) => {
  const [uploading, setUploading] = useState<{ [key: string]: boolean }>({});
  const [reportGenerating, setReportGenerating] = useState<{ [key: string]: boolean }>({});
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = useState<string>('');
  const [availableFiles, setAvailableFiles] = useState<string[]>([]);
  const [showInitializeSFAModal, setShowInitializeSFAModal] = useState<boolean>(false);
  const [selectedSFADeal, setSelectedSFADeal] = useState<string>('');

  const handleGenerateReportClick = (dealName: string) => {
    openReportModal(dealName);
  };

  const openReportModal = async (dealName: string) => {
    setSelectedDeal(dealName);
    setShowReportModal(true);

    try {
      const response = await apiCall(`/get-legal-files/${clientName}`, {
        method: 'GET',
        params: { dealName },
      });

      setAvailableFiles(response.filenames || []);
    } catch (error) {
      console.error('Error fetching available files:', error);
      setAvailableFiles([]);
      setToastMsg('Failed to fetch available files.');
      setToastStatus(false);
      setToast(true);
    }
  };

  const handleGenerateReport = async (selectedFile: string) => {
    setReportGenerating((prev) => ({ ...prev, [selectedDeal]: true }));
    try {
      const response = await apiCall(`/generate-legal-report`, {
        method: 'POST',
        data: {
          clientName,
          dealName: selectedDeal,
          selectedFile,
        },
        responseType: 'blob',
        transformResponse: (res: any) => res,
      });

      const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedDeal}-Legal-Report.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error generating legal report:', error);
      setToastMsg('Failed to generate legal report.');
      setToastStatus(false);
      setToast(true);
    } finally {
      setReportGenerating((prev) => ({ ...prev, [selectedDeal]: false }));
      setShowReportModal(false);
      setSelectedDeal('');
    }
  };

  const handleInitializeSFA = (dealName: string) => {
    setSelectedSFADeal(dealName);
    setShowInitializeSFAModal(true);
  };

  const handleDeleteDeal = async (dealName: string) => {
    if (!clientName) {
      setToastMsg('Client name is not available');
      setToastStatus(false);
      setToast(true);
      return;
    }

    if (window.confirm(`Are you sure you want to delete the deal: ${dealName}?`)) {
      try {
        const response = await apiCall(`/collection/${clientName}/company/${dealName}`, {
          method: 'DELETE',
        });
        if (response.status === 200) {
          const newData = data.filter((item) => item !== dealName);
          setData(newData);
          setToastMsg('Deal deleted successfully');
          setToastStatus(true);
          setToast(true);
        }
      } catch (error) {
        setToastMsg('Something went wrong');
        setToastStatus(false);
        setToast(true);
      }
    }
  };

  const handleRowDoubleClick = (dealName: string) => {
    onDealClick(dealName); // ✅ Use the new prop to handle deal click
  };

  const handleCloseInitializeSFAModal = () => {
    setShowInitializeSFAModal(false);
    setSelectedSFADeal('');
  };

  return (
    <div className="overflow-x-auto">
      {/* Replicated styling from HighYield */}
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-200">
          <tr>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">Deal Name</th>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">Upload</th>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">Delete</th>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">Key Terms</th>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">SFA</th>
          </tr>
        </thead>
        <tbody>
          {data.map((deal, index) => (
            <tr
              key={index}
              className="border-b last:border-b-0 hover:bg-gray-100 cursor-pointer"
              onDoubleClick={() => handleRowDoubleClick(deal)} // ✅ Trigger onDealClick on double-click
            >
              <td className="py-4 px-6 text-sm text-gray-800">{deal}</td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                {uploading[deal] ? (
                  <img src={Spinner} alt="Uploading..." className="w-5 h-5 mx-auto" />
                ) : (
                  <img
                    src={file_upload}
                    alt="Upload Legal Documents"
                    className="cursor-pointer w-5 h-5 mx-auto"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering row double-click
                      setSelectedDeal(deal);
                      setShowUploadModal(true);
                    }}
                  />
                )}
              </td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                <img
                  src={delete_icon}
                  alt="Delete"
                  className="cursor-pointer w-5 h-5 mx-auto"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering row double-click
                    handleDeleteDeal(deal);
                  }}
                />
              </td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                {reportGenerating[deal] ? (
                  <img src={Spinner} alt="Generating..." className="w-8 h-8 mx-auto" />
                ) : (
                  <img
                    src={ReportIcon}
                    alt="Generate Excel Report"
                    className="cursor-pointer w-5 h-5 mx-auto"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering row double-click
                      handleGenerateReportClick(deal);
                    }}
                  />
                )}
              </td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                <img
                  src={initialize_icon}
                  alt="Initialize SFA"
                  className="cursor-pointer w-5 h-5 mx-auto"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering row double-click
                    handleInitializeSFA(deal);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Upload Modal */}
      {showUploadModal && (
        <LegalUploadModal
          dealName={selectedDeal}
          clientName={clientName}
          onClose={() => {
            setShowUploadModal(false);
            setSelectedDeal('');
          }}
          setToast={setToast}
          setToastMsg={setToastMsg}
          setToastStatus={setToastStatus}
          onUploadSuccess={() => {
            setToastMsg('Legal document uploaded successfully.');
            setToastStatus(true);
            setToast(true);
          }}
        />
      )}

      {/* Report Modal */}
      {showReportModal && (
        <LegalReportModal
          isOpen={showReportModal}
          availableFiles={availableFiles}
          onRequestClose={() => setShowReportModal(false)}
          onGenerateReport={handleGenerateReport}
        />
      )}

      {/* Initialize SFA Modal */}
      {showInitializeSFAModal && (
        <InitializeSFAModal
          isOpen={showInitializeSFAModal}
          onRequestClose={handleCloseInitializeSFAModal}
          clientName={clientName}
          dealName={selectedSFADeal}
        />
      )}

      {/* Toast Message */}
      {showToast && (
        <ToastMessage
          isSuccess={toastStatus}
          toastMsg={toastMsg}
          setToast={setToast}
        />
      )}
    </div>
  );
};

export default LegalTable;
