// src/components/highyield/highyield-main.tsx

import React, { useEffect, useState } from 'react';
import PageHeader from '../ui/pageHeader/pageHeader';
import { useQuery } from '@tanstack/react-query';
import { getClientName } from '../../utils/authUtils';
import { apiCall } from '../../utils/api';
import InitializeHighYieldModal from './initialize-highyield-modal';
import HighYieldTable from './highyield-table';
import AnalyseHighYield from './analyse-highyield'; // <-- new import

interface HighYieldMainProps {}

const HighYieldMain: React.FC<HighYieldMainProps> = () => {
  // State for client name
  const [clientName, setClientName] = useState<string>('');

  // State for existing deals
  const [deals, setDeals] = useState<string[]>([]);

  // State for controlling the visibility of the modal
  const [showModal, setShowModal] = useState<boolean>(false);

  // Toast state
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);

  // New states for Chat
  const [selectedDeal, setSelectedDeal] = useState<string>(''); // which deal is chosen
  const [showChat, setShowChat] = useState<boolean>(false);      // toggle chat mode

  /**
   * Fetch client name on component mount.
   */
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const cn = await getClientName();
        if (cn) {
          setClientName(cn);
        } else {
          console.warn('Client name not found.');
        }
      } catch (error) {
        console.error('Error fetching client name:', error);
      }
    };
    fetchClient();
  }, []);

  /**
   * React Query: Fetch high-yield deals based on the client name.
   */
  const { data, error, isLoading } = useQuery<string[], Error>({
    queryKey: ['highyield-deals', clientName],
    queryFn: async () => {
      if (!clientName) return [];
      // Call the new high-yield portfolio endpoint
      return await apiCall(
        `/highyieldportfolio?collectionName=${encodeURIComponent(clientName)}`,
        { method: 'GET' }
      );
    },
  });

  /**
   * Populate local state once data arrives.
   */
  useEffect(() => {
    if (Array.isArray(data)) {
      setDeals(data);
    }
  }, [data]);

  /**
   * Handler to open the Initialize High Yield modal.
   */
  const openModal = () => {
    setShowModal(true);
  };

  /**
   * Handler to close the Initialize High Yield modal.
   */
  const closeModal = () => {
    setShowModal(false);
  };

  /**
   * Handle a row click in HighYieldTable to open chat.
   */
  const handleDealClick = (dealName: string) => {
    setSelectedDeal(dealName);
    setShowChat(true);
  };

  /**
   * Return from chat view to table view
   */
  const closeChat = () => {
    setShowChat(false);
    setSelectedDeal('');
  };

  // While data is loading, you can show a spinner or text
  if (isLoading) {
    return (
      <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
        <PageHeader title="High Yield" />
        <p>Loading High Yield deals...</p>
      </div>
    );
  }

  // If we are in “chat mode,” show the AnalyseHighYield
  if (showChat && selectedDeal) {
    return (
      <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
        {/* Chat Interface */}
        <AnalyseHighYield
          companyName={selectedDeal}
          clientName={clientName}
          onCloseChat={closeChat}
        />
      </div>
    );
  }

  // Otherwise, show the table (default view)
  return (
    <div className="py-4 px-6 font-inter w-full min-h-screen bg-gray-50">
      {/* Page Header */}
      <div className="flex items-center justify-between mb-6">
        <PageHeader title="High Yield" />
      </div>

      {/* Initialize High Yield Button */}
      <div className="flex justify-end mb-4">
        <button
          onClick={openModal}
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded shadow-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label="Initialize New High Yield"
        >
          Initialize New High Yield
        </button>
      </div>

      {/* High Yield Deals Table */}
      <HighYieldTable
        data={deals}
        setData={setDeals}
        clientName={clientName}
        showToast={showToast}
        setToast={setShowToast}
        toastMsg={toastMsg}
        setToastMsg={setToastMsg}
        toastStatus={toastStatus}
        setToastStatus={setToastStatus}
        // Pass the row click handler to open chat
        onDealClick={handleDealClick}
      />

      {/* Initialize High Yield Modal */}
      {showModal && (
        <InitializeHighYieldModal
          setShowModal={setShowModal}
          clientName={clientName}
          setToast={setShowToast}
          setToastMsg={setToastMsg}
          setToastStatus={setToastStatus}
        />
      )}
    </div>
  );
};

export default HighYieldMain;
