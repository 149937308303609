// src\components\monitoring\Dashboard\ChartPortfolio.tsx
import React, { useState } from 'react';
import {
  ChartBarIcon,
  ChartPieIcon,
  PresentationChartLineIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';

interface ChartPortfolioProps {
  selectedCharts: string[];
  toggleChartSelection: (chart: string) => void;
}

interface AvailableChart {
  name: string;
  type: 'bar' | 'line' | 'dual-axis';
  category: string;
}

const availableCharts: AvailableChart[] = [
  { name: 'Revenue', type: 'bar', category: 'Bar Charts' },
  { name: 'EBITDA', type: 'bar', category: 'Bar Charts' },
  { name: 'Leverage Ratio', type: 'bar', category: 'Bar Charts' },
  { name: 'Gross Profit', type: 'bar', category: 'Bar Charts' },
  { name: 'Free Cash Flow', type: 'bar', category: 'Bar Charts' },
  { name: 'Gross Margin Percentage', type: 'line', category: 'Line Charts' },
  { name: 'EBITDA Margin Percentage', type: 'line', category: 'Line Charts' },
  { name: 'Free Cash Flow Comparison', type: 'line', category: 'Line Charts' },
  { name: 'Cash Flow vs Capex', type: 'dual-axis', category: 'Dual-Axis Charts' },
  { name: 'DSCR', type: 'line', category: 'Line Charts' },
];

const ChartPortfolio: React.FC<ChartPortfolioProps> = ({
  selectedCharts,
  toggleChartSelection,
}) => {
  const chartsByCategory = availableCharts.reduce((acc, chart) => {
    if (!acc[chart.category]) {
      acc[chart.category] = [];
    }
    acc[chart.category].push(chart);
    return acc;
  }, {} as { [key: string]: AvailableChart[] });

  const [expandedCategories, setExpandedCategories] = useState<{ [key: string]: boolean }>({});

  const toggleCategory = (category: string) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const getChartIcon = (type: string) => {
    switch (type) {
      case 'bar':
        return ChartBarIcon;
      case 'line':
        return PresentationChartLineIcon;
      case 'dual-axis':
        return ChartPieIcon;
      default:
        return ChartBarIcon;
    }
  };

  return (
    <div>
      <h2 className="text-lg font-medium mb-3">Select Charts to Display</h2>

      {/* Accordion Layout for Small Screens */}
      <div className="md:hidden">
        {Object.keys(chartsByCategory).map((category) => (
          <div key={category} className="mb-4 border rounded-md">
            <button
              onClick={() => toggleCategory(category)}
              className="w-full flex justify-between items-center p-3 bg-gray-100 hover:bg-gray-200 focus:outline-none"
              aria-expanded={expandedCategories[category] || false}
            >
              <span className="text-left font-semibold">{category}</span>
              {expandedCategories[category] ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </button>
            {expandedCategories[category] && (
              <div className="p-3 grid grid-cols-1 sm:grid-cols-2 gap-3 overflow-auto">
                {chartsByCategory[category].map((chart) => {
                  const Icon = getChartIcon(chart.type);
                  const isSelected = selectedCharts.includes(chart.name);
                  return (
                    <button
                      key={chart.name}
                      onClick={() => toggleChartSelection(chart.name)}
                      className={`p-2 w-24 h-24 border rounded-lg flex flex-col items-center justify-center text-center ${
                        isSelected
                          ? 'bg-indigo-600 text-white shadow-md'
                          : 'bg-white hover:bg-gray-100'
                      } transition-colors duration-200 focus:outline-none`}
                    >
                      <Icon
                        className={`h-5 w-5 mb-1 ${
                          isSelected ? 'text-white' : 'text-gray-500'
                        }`}
                      />
                      <span className="text-xs font-medium">{chart.name}</span>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Grid Layout for Large Screens */}
      <div className="hidden md:block max-w-full overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {Object.keys(chartsByCategory).map((category) => (
            <div key={category} className="border rounded-md p-4 bg-gray-100">
              <h3 className="text-md font-semibold mb-2">{category}</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
                {chartsByCategory[category].map((chart) => {
                  const Icon = getChartIcon(chart.type);
                  const isSelected = selectedCharts.includes(chart.name);
                  return (
                    <button
                      key={chart.name}
                      onClick={() => toggleChartSelection(chart.name)}
                      className={`p-2 w-24 h-24 border rounded-lg flex flex-col items-center justify-center text-center ${
                        isSelected
                          ? 'bg-indigo-600 text-white shadow-md'
                          : 'bg-white hover:bg-gray-100'
                      } transition-colors duration-200 focus:outline-none`}
                    >
                      <Icon
                        className={`h-5 w-5 mb-1 ${
                          isSelected ? 'text-white' : 'text-gray-500'
                        }`}
                      />
                      <span className="text-xs font-medium">{chart.name}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChartPortfolio;
