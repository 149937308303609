// src/components/deal analysis/deal-table.tsx
import React, { useState } from 'react';
import axios from 'axios';
import delete_icon from '../../images/delete-bin.svg';
import ReportIcon from '../../images/report.svg';
import ToastMessage from '../ui/toastMessage/toast-message';
import Spinner from '../../images/DarkSpinner.gif';
import FileSelectionModal from './file-selection-modal'; // Adjust the import path if necessary
import { apiCall } from '../../utils/api';

interface DealTableProps {
  data: string[];
  setData: React.Dispatch<React.SetStateAction<string[]>>;
  setHeader: React.Dispatch<React.SetStateAction<string>>;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  companyName: string;
  clientName: string;
  onDealClick: (dealName: string) => void; // ✅ Added this line
}

const DealTable: React.FC<DealTableProps> = ({
  data,
  setData,
  setHeader,
  companyName,
  setCompanyName,
  clientName,
  onDealClick, // ✅ Destructure the new prop
}) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  const [generatingReports, setGeneratingReports] = useState<{ [key: string]: boolean }>({});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDealName, setSelectedDealName] = useState<string>('');

  const closeModal = () => setIsModalOpen(false);

  const deleteDeal = async (name: string) => {
    if (!clientName) {
      setToastMsg('Client name is not available');
      setToastStatus(false);
      setShowToast(true);
      return;
    }

    if (window.confirm(`Are you sure you want to delete the deal: ${name}?`)) {
      try {
        const response = await apiCall(`/collection/${clientName}/company/${name}`, {
          method: 'DELETE',
        });

        if (response.status === 200) {
          const newData = data.filter((item) => item !== name);
          setData(newData);
          setToastMsg('Deal deleted successfully');
          setToastStatus(true);
          setShowToast(true);
        }
      } catch (error) {
        setToastMsg('Something went wrong');
        setToastStatus(false);
        setShowToast(true);
      }
    }
  };

  const generateReport = (name: string) => {
    setSelectedDealName(name);
    setIsModalOpen(true);
  };

  const handleGenerateDeal = async (
    format: string,
    reportType: string,
    selectedFiles: { [section: string]: string[] }
  ) => {
    closeModal();
    setGeneratingReports((prevState) => ({ ...prevState, [selectedDealName]: true }));

    if (!selectedDealName) {
      setToastMsg('Company name is required to generate the report');
      setToastStatus(false);
      setShowToast(true);
      setGeneratingReports((prevState) => ({ ...prevState, [selectedDealName]: false }));
      return;
    }

    try {
      const response = await apiCall(
        `/generate-report/${clientName}/company/${selectedDealName}`,
        {
          method: 'POST',
          data: { selectedFiles, format, reportType },
          responseType: 'blob',
          transformResponse: (res: any) => res,
        }
      );

      const fileExtension = format === 'word' ? 'docx' : 'pdf';
      const mimeType =
        format === 'word'
          ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          : 'application/pdf';

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: mimeType })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${selectedDealName}-Credit-Memo.${fileExtension}`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        console.error('Failed to generate report:', err);
      } else {
        console.error('An unknown error occurred:', err);
      }
      setToastMsg('Failed to generate report');
      setToastStatus(false);
      setShowToast(true);
    } finally {
      setGeneratingReports((prevState) => ({ ...prevState, [selectedDealName]: false }));
    }
  };

  const handleRowDoubleClick = (name: string) => {
    onDealClick(name); // ✅ Use the new prop to handle deal click
  };

  return (
    <div className="overflow-x-auto">
      {/* Replicated styling from HighYield */}
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-200">
          <tr>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">Name</th>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">Delete</th>
            <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">Report</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              className="border-b last:border-b-0 hover:bg-gray-100 cursor-pointer"
              key={`${item}-${index + 1}`}
              onDoubleClick={() => handleRowDoubleClick(item)} // ✅ Trigger onDealClick on double-click
            >
              <td className="py-4 px-6 text-sm text-gray-800">{item}</td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                <img
                  src={delete_icon}
                  className="cursor-pointer mx-auto"
                  style={{ display: 'block' }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering row double-click
                    deleteDeal(item);
                  }}
                />
              </td>
              <td className="py-4 px-6 text-sm text-gray-800 text-center">
                {generatingReports[item] ? (
                  <img
                    src={Spinner}
                    alt="Loading..."
                    className="w-8 h-8 mx-auto"
                    style={{ display: 'block' }}
                  />
                ) : (
                  <img
                    src={ReportIcon}
                    alt="Generate Report"
                    className="cursor-pointer mx-auto"
                    style={{ display: 'block' }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering row double-click
                      generateReport(item);
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <FileSelectionModal
        isModalOpen={isModalOpen}
        clientName={clientName}
        companyName={selectedDealName}
        closeModal={closeModal}
        handleGenerateDeal={handleGenerateDeal}
      />

      {showToast && (
        <ToastMessage
          isSuccess={toastStatus}
          setToast={setShowToast}
          toastMsg={toastMsg}
        />
      )}
    </div>
  );
};

export default DealTable;
